<template>
  <div>
    <breadcrumb-bar
      :title="appTitle"
      :breadcrumbs="breadcrumbs"
    />
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-form>
            <base-material-card
              color="primary"
              icon="mdi-account-tie"
              inline
              class="px-5 py-3"
            >
              <template v-slot:after-heading>
                <v-card-text class="display-3 font-weight-light">
                  {{ appTitle }}
                </v-card-text>
              </template>
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    v-model="name"
                    :error-messages="nameErrors"
                    dense
                    class="purple-input"
                    :label="this.$t('addPartnerLabelName')"
                    @input="$v.name.$touch()"
                    @blur="$v.name.$touch()"
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="email"
                    :error-messages="emailErrors"
                    dense
                    class="purple-input"
                    :label="this.$t('addPartnerLabelEMail')"
                    @input="$v.email.$touch()"
                    @blur="$v.email.$touch()"
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="phoneNumber"
                    v-mask="'(###) ###-####'"
                    :error-messages="phoneErrors"
                    dense
                    class="purple-input"
                    :label="this.$t('addPartnerLabelPhoneNo')"
                    @input="$v.phoneNumber.$touch()"
                    @blur="$v.phoneNumber.$touch()"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="addressLine1"
                    :error-messages="address1Errors"
                    dense
                    class="purple-input"
                    :label="this.$t('addPartnerLabelAddress1')"
                    @input="$v.addressLine1.$touch()"
                    @blur="$v.addressLine1.$touch()"
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="city"
                    :error-messages="cityErrors"
                    dense
                    class="purple-input"
                    :label="this.$t('addPartnerLabelCity')"
                    @input="$v.city.$touch()"
                    @blur="$v.city.$touch()"
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="postalCode"
                    :error-messages="postalErrors"
                    dense
                    class="purple-input"
                    :label="this.$t('addPartnerLabelPostalCode')"
                    @input="$v.postalCode.$touch()"
                    @blur="$v.postalCode.$touch()"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="addressLine2"
                    :error-messages="address2Errors"
                    dense
                    class="purple-input"
                    :label="this.$t('addPartnerLabelAddress2')"
                    @input="$v.addressLine2.$touch()"
                    @blur="$v.addressLine2.$touch()"
                  />
                </v-col>
                <v-col cols="3">
                  <v-select
                    v-model="stateCode"
                    :items="stateCodes"
                    item-text="stateName"
                    item-value="stateCode"
                    dense
                    class="purple-input"
                    :label="this.$t('addPartnerLabelState')"
                  />
                </v-col>
                <v-col cols="3">
                  <v-select
                    v-model="countryCode"
                    :error-messages="countryErrors"
                    :items="countryCodes"
                    item-text="countryName"
                    item-value="countryCode"
                    dense
                    class="purple-input"
                    :label="this.$t('addPartnerLabelCountry')"
                    @input="$v.countryCode.$touch()"
                    @blur="$v.countryCode.$touch()"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-select
                    v-model="partnerType"
                    :error-messages="partnerTypeErrors"
                    :items="partnerTypes"
                    item-text="description"
                    item-value="partnerType"
                    dense
                    multiple
                    class="purple-input"
                    :label="this.$t('addPartnerLabelPartnerType')"
                    @input="$v.partnerType.$touch()"
                    @blur="$v.partnerType.$touch()"
                  />
                </v-col>
                <v-col>
                  <v-select
                    v-model="dashboardType"
                    :error-messages="dashboardTypeErrors"
                    :items="dashboardTypes"
                    item-text="value"
                    item-value="key"
                    dense
                    class="purple-input"
                    :label="this.$t('addPartnerLabelDashType')"
                    @input="$v.dashboardType.$touch()"
                    @blur="$v.dashboardType.$touch()"
                  />
                </v-col>
              </v-row>
              <template v-slot:actions>
                <div>
                  <v-btn
                    color="success"
                    @click="onSubmit"
                  >
                    {{ submitBtnText }}
                  </v-btn>
                  <v-btn
                    text
                    to="/masterdata/partners"
                  >
                    {{ $t('buttonClose') }}
                  </v-btn>
                </div>
              </template>
            </base-material-card>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
    <error-dialog
      :dialog="showErrorDialog"
      title="Failed to Load the Application"
      :message="errorMessage"
      @update:dialog="showErrorDialog = false"
    />
    <process-overlay
      :processing="modelProcessing"
    />
  </div>
</template>

<script>
  import { mapFields } from 'vuex-map-fields'
  import { required, maxLength, email } from 'vuelidate/lib/validators'
  import { validationMixin } from 'vuelidate'
  import i18n from '@/i18n'
  import PartnerConfig from '@/configuration/partnerConfiguration'

  import masterDataAPI from '@/services/masterData'
  import partnersAPI from '@/services/partnerServices'

  function splitPartnerTypes (denseType) {
    var mask = 1
    var iter = 0
    var result = []
    // iterate through each bit
    while (denseType !== 0) {
      var bit = denseType & mask
      // find bits that are 1
      if (bit !== 0) {
        // add that bit's value to the list
        result.push(bit << iter)
      }
      iter++
      denseType >>= 1
    }
    // return list ex: 10 -> [2, 8], 7 -> [1, 2, 4]
    return result
  }

  function initialState () {
    return {
      appTitle: i18n.t('titleAddPartner'),
      breadcrumbs: [
        {
          text: i18n.t('bcHome'),
          disabled: false,
          href: '/dashboard',
        },
        {
          text: i18n.t('bcSettings'),
          disabled: true,
          href: '/settings',
        },
        {
          text: i18n.t('bcPartners'),
          disabled: false,
          href: '/settings/listings',
        },
        {
          text: i18n.t('bcAddPartner'),
          disabled: true,
          href: '/settings/editPartner',
        },
      ],
      partnerData: PartnerConfig.Partner,
      name: '',
      partnerType: [],
      dashboardType: undefined,
      addressLine1: '',
      addressLine2: '',
      city: '',
      stateCode: '',
      countryCode: '',
      postalCode: '',
      phoneNumber: '',
      email: '',
      stateCodes: [],
      countryCodes: [],
      showErrorDialog: false,
      errorMessage: '',
      modelProcessing: false,
      submitBtnText: i18n.t('buttonCreate'),
    }
  }

  export default {
    Name: 'EditPartner',
    components: {
      ErrorDialog: () => import('@/views/components/core/ErrorDialog'),
      BreadcrumbBar: () => import('@/views/components/core/BreadcrumbBar'),
      ProcessOverlay: () => import('@/views/components/core/Overlay'),
    },
    mixins: [validationMixin],
    props: {
      partnerId: {
        type: String,
        default: '0',
      },
    },
    data: function () {
      return initialState()
    },
    computed: {
      ...mapFields({
        currentUser: 'currentUser',
        partnerTypes: 'partnerTypes',
        dashboardTypes: 'dashboardTypes',
      }),
      nameErrors () {
        const errors = []
        if (!this.$v.name.$dirty) return errors
        !this.$v.name.maxLength && errors.push(this.$t('addPartnerErrorNameRequired'))
        !this.$v.name.required && errors.push(this.$t('addPartnerErrorNameMaxLen'))
        return errors
      },
      emailErrors () {
        const errors = []
        if (!this.$v.email.$dirty) return errors
        !this.$v.email.maxLength && errors.push(this.$t('addPartnerErrorEMailMaxLen'))
        !this.$v.email.required && errors.push(this.$t('addPartnerErrorEMailRequired'))
        !this.$v.email.email && errors.push(this.$t('addPartnerErrorEMailValid'))
        return errors
      },
      phoneErrors () {
        const errors = []
        if (!this.$v.phoneNumber.$dirty) return errors
        !this.$v.phoneNumber.maxLength && errors.push(this.$t('addPartnerErrorPhoneMaxLen'))
        return errors
      },
      address1Errors () {
        const errors = []
        if (!this.$v.addressLine1.$dirty) return errors
        !this.$v.addressLine1.required && errors.push(this.$t('addPartnerErrorAddress1Required'))
        !this.$v.addressLine1.maxLength && errors.push(this.$t('addPartnerErrorAddressMaxLen'))
        return errors
      },
      address2Errors () {
        const errors = []
        if (!this.$v.addressLine2.$dirty) return errors
        !this.$v.addressLine2.maxLength && errors.push(this.$t('addPartnerErrorAddressMaxLen'))
        return errors
      },
      cityErrors () {
        const errors = []
        if (!this.$v.city.$dirty) return errors
        !this.$v.city.required && errors.push(this.$t('addPartnerErrorCityRequired'))
        !this.$v.city.maxLength && errors.push(this.$t('addPartnerErrorCityMaxLen'))
        return errors
      },
      postalErrors () {
        const errors = []
        if (!this.$v.postalCode.$dirty) return errors
        !this.$v.postalCode.required && errors.push(this.$t('addPartnerErrorPostalCodeRequired'))
        !this.$v.postalCode.maxLength && errors.push(this.$t('addPartnerErrorPostalCodeMaxLen'))
        return errors
      },
      countryErrors () {
        const errors = []
        if (!this.$v.countryCode.$dirty) return errors
        !this.$v.countryCode.required && errors.push(this.$t('addPartnerErrorCountryRequired'))
        return errors
      },
      partnerTypeErrors () {
        const errors = []
        if (!this.$v.partnerType.$dirty) return errors
        !this.$v.partnerType.required && errors.push(this.$t('addPartnerErrorPartnerTypeRequired'))
        return errors
      },
      dashboardTypeErrors () {
        const errors = []
        if (!this.$v.dashboardType.$dirty) return errors
        !this.$v.dashboardType.required && errors.push(this.$t('addPartnerErrorDashboardTypeRequired'))
        return errors
      },
    },
    mounted: function () {
      if (this.partnerId !== '0') {
        this.modelProcessing = true
        this.submitBtnText = this.$t('buttonSave')
        this.appTitle = this.$t('titleEditPartner')
        this.breadcrumbs[this.breadcrumbs.length - 1].text = this.$t('bcEditPartner')
        partnersAPI.getPartnerById(this.partnerId)
          .then(response => {
            this.partnerData = response.data
            this.name = this.partnerData.name
            this.email = this.partnerData.eMail
            this.phoneNumber = this.partnerData.phoneNumber
            this.addressLine1 = this.partnerData.addressLine1
            this.addressLine2 = this.partnerData.addressLine2
            this.city = this.partnerData.city
            this.postalCode = this.partnerData.postalCode
            this.stateCode = this.partnerData.stateCode
            this.countryCode = this.partnerData.countryCode
            this.partnerType = splitPartnerTypes(this.partnerData.partnerType)
            this.dashboardType = String(this.partnerData.dashboardType)
            this.modelProcessing = false
          })
      }
      if (this.partnerTypes.length === 0) {
        this.$store.dispatch('GET_PARTNER_TYPES')
      }
      if (this.dashboardTypes.length === 0) {
        this.$store.dispatch('GET_DASHBOARD_TYPES')
      }
      masterDataAPI.getStateCodes()
        .then(response => {
          this.stateCodes = response.data
        })
      masterDataAPI.getCountryCodes()
        .then(response => {
          this.countryCodes = response.data
        })
    },
    validations: {
      name: {
        required,
        maxLength: maxLength(120),
      },
      email: {
        required,
        maxLength: maxLength(255),
        email,
      },
      phoneNumber: {
        maxLength: maxLength(25),
      },
      addressLine1: {
        required,
        maxLength: maxLength(50),
      },
      addressLine2: {
        maxLength: maxLength(50),
      },
      city: {
        required,
        maxLength: maxLength(50),
      },
      postalCode: {
        required,
        maxLength: maxLength(12),
      },
      countryCode: {
        required,
      },
      partnerType: {
        required,
      },
      dashboardType: {
        required,
      },
    },
    methods: {
      onSubmit () {
        this.$v.$touch()
        if (!this.$v.$invalid) {
          this.modelProcessing = true
          this.partnerData.Name = this.name
          this.partnerData.PartnerType = this.partnerType.reduce((a, b) => a + b, 0)
          this.partnerData.EMail = this.email
          this.partnerData.PhoneNumber = this.phoneNumber
          this.partnerData.AddressLine1 = this.addressLine1
          this.partnerData.AddressLine2 = this.addressLine2
          this.partnerData.City = this.city
          this.partnerData.PostalCode = this.postalCode
          this.partnerData.StateCode = this.stateCode
          this.partnerData.CountryCode = this.countryCode
          this.partnerData.dashboardType = parseInt(this.dashboardType)
          if (this.partnerId === '0') {
            partnersAPI.addNewPartner(this.partnerData)
              .then(response => {
                this.modelProcessing = false
                Object.assign(this.$data, initialState())
                this.$router.push('/masterdata/partners')
              })
              .catch(error => {
                this.showErrorDialog = true
                this.errorMessage = error.message
                this.modelProcessing = false
              })
          } else {
            partnersAPI.updatePartner(this.partnerData)
              .then(response => {
                this.modelProcessing = false
                Object.assign(this.$data, initialState())
                this.$router.push('/masterdata/partners')
              })
              .catch(error => {
                this.modelProcessing = false
                this.showErrorDialog = true
                this.errorMessage = error.errorMessage
              })
          }
        }
      },
    },
  }
</script>
