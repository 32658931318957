export default {
  Partner: {
    Name: '',
    PartnerType: -1,
    AddressLine1: '',
    AddressLine2: '',
    City: '',
    StateCode: '',
    PostalCode: '',
    CountryCode: '',
    PhoneNumber: '',
    EMail: '',
    StatusCode: 1,
  },
}
